<template>
  <div>
    <div class="slide-x-item">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "slide-x"
}
</script>

<style scoped>
.slide-x {
  padding: 0.2rem 0;
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
}
</style>
