<template>
  <div class="play">
    <van-nav-bar left-arrow :fixed="true" :border="false" @click-left="onClickLeft">
<!--      <template #right>-->
<!--        <van-icon name="question-o" size="18"/>-->
<!--      </template>-->
    </van-nav-bar>
    <div>
      <div class="video-content" @click="toiosPlay">
        <van-icon name="play-circle-o" color="var(--title-color)"/>
      </div>
    </div>
    <div>
      <div>
        <van-skeleton style="margin-top: 10px" title :row="4" row-width="100%" :loading="!setFan.placeholder">
          <div @click="show = true" style="border:none">
            <div class="video-title">
              <div class="video-title-name">
                <van-skeleton :row="1" row-width="100%" :loading="!setFan.placeholder"/>
                {{ setFan.placeholder }}
              </div>
              <div class="video-title-info">
              <span>
                {{$t('introduction')}}<van-icon name="arrow"/>
              </span>
              </div>
            </div>
          </div>
          <!-- 评分 -->
          <div class="score">
            <div class="vod-basics-info">
              <div><span>{{ infos.vod_year }}</span></div>
              <div>|</div>
              <div><span>{{ infos.vod_douban_score }}</span></div>
            </div>
            <div class="vod-tag">
              {{ infos.vod_tag }}
            </div>
          </div>
          <div class="operation">
            <div class="operation-item" @click="download()">
              <div class="operation-item-image">
                <van-image class="operation-item-image-content"
                           :src="require('@/assets/download.png')"
                />
              </div>

              <div>{{$t('download')}}</div>
            </div>
            <div class="operation-item" @click="openFeedback">
              <div class="operation-item-image">
                <van-image class="operation-item-image-content"
                           :src="require('@/assets/feedback.png')"
                />
              </div>
              <div>{{$t('feedback')}}</div>
            </div>
          </div>
        </van-skeleton>

        <!-- 弹出框---------------------------------------------------------------------------- -->
        <van-action-sheet
            v-model="show"
            :round="false"
            :closeable="true"
            :overlay="true"
            :title="infos.vod_name"
            close-icon="arrow-down"
        >
          <div class="content">
            <div class="intro contentPublic">
              <div v-show="infos.vod_director">{{$t('director')}}: <span>{{ infos.vod_director }}</span></div>
              <div v-show="infos.vod_actor">{{$t('actor')}}: <span>{{ infos.vod_actor }}</span></div>
              <div v-show="infos.vod_year">{{$t('releaseTime')}}: <span>{{ infos.vod_year }}</span></div>
              <div v-show="infos.vod_tag"> {{$t('genres')}}:
                <span style="word-wrap: break-word; word-break: break-all;">{{ infos.vod_tag }}</span>
              </div>
            </div>
            <div class="synopsis">{{ infos.vod_blurb }}</div>
          </div>
        </van-action-sheet>
        <!-- ------------------------------------------------------------------------------------ -->
      </div>
      <!-- 下载反馈分享 -->
      <!--      <div class="xff">-->
      <!--        <div class="xff-left">-->
      <!--          <div @click="openFeedback"><img src="../../assets/ic_feedback.png" alt=""></div>-->
      <!--          <div><img src="../../assets/ic_video_share.png" alt=""></div>-->
      <!--        </div>-->
      <!--      </div>-->
      <van-overlay :show="kongzhi" @click="kongzhis">
        <div class="wrapper">
          <div class="block" @click.stop>
            <div>Feedback</div>
            <van-form @submit="onSubmit">
              <div style="margin: 16px;">
                <van-radio-group v-model="radio"><!--@change="onchange"-->
                  <van-radio :value="item" v-for="item in feedbackTags" :key="item" :name="item">{{ item }}</van-radio>
                </van-radio-group>
                <van-field v-model="message" name="content" rows="3" :autosize="true" type="textarea" :error-message="errorMessage"
                           error-message-align="right" :placeholder='setFan.placeholder + "   " + setFan.tvNum' show-word-limit/>
                <van-button round block type="danger" native-type="submit">{{$t('sendButton')}}</van-button>
              </div>
            </van-form>
          </div>
        </div>
      </van-overlay>
      <!-- 播放路径选择 -->
      <div class="playUrl">
        <div>
          <div :class="item.type == urlBtnActive ? 'urlsActive' : 'Urls'" v-for="item in urlBtn" :key="item.type"
               @click="changeAudio(item.type)">
            {{ item.type_name }}
          </div>
        </div>
      </div>
      <div class="season-info" v-show="typePid === 2">
        <div class="season-list">
          <van-popover
              v-model="showPopover"
              theme="dark"
              trigger="click"
              :actions="seasonList"
              placement="bottom-start"
              @select="changeSeason"
          >
            <template #reference>
              <div class="season-info-button">
                {{ seasonTitle }}
                <van-icon name="arrow-down"/>
              </div>
            </template>
          </van-popover>
        </div>
        <div class="season-collection" @click="seasonShow = true">
          {{$t('updateTo')}} {{ infos.vod_serial }}<van-icon name="arrow"/>
        </div>
      </div>
      <!-- 第几集 -->
      <div class="episodes-list">
        <slidex class="episodes-list-content">
          <slidexitem v-for="item in tvInfons"
            :key="item.id"
          >
            <div :class="item.collection == tvActive ? 'episodes-list-content-item-active' : 'episodes-list-content-item'"
                 @click="changeCollection(item.id, item.collection)"
            >
              {{ item.collection }}
            </div>
          </slidexitem>
        </slidex>
      </div>

      <div class="playNum">
        <!--        <div class="numNav">-->
        <!--        </div>-->


        <div style="padding: 0.1rem">
          <slidex>
            <slidexitem v-for="item in screeninfo" :key="item.id">
              <div style="width: 3.3rem;display:flex;justify-content: center;" @click="toPlays(item.id)">
                <div>
                  <van-image
                      width="3.1rem"
                      height="4.37333rem"
                      radius="0.2rem"
                      :src="item.vod_pic"
                  />
                  <div class="rec_vod_name" style="width: 3.1rem">
                    {{ item.vod_name }}
                  </div>
                </div>
              </div>
            </slidexitem>
          </slidex>
        </div>
      </div>
    </div>

    <!-- 集列表 -->
    <van-action-sheet
        v-model="seasonShow"
        :round="false"
        :closeable="true"
        :overlay="true"
        :title="$t('selectEpisode')"
        close-icon="arrow-down"
    >
      <div class="season-sheet-content">
        <div class="season-sheet-content-bottom">
          <div :class="item.collection == tvActive ? 'season-sheet-content-bottom-item season-sheet-content-bottom-item-active' : 'season-sheet-content-bottom-item'"
               v-for="item in tvInfons"
               :key="item.id"
               @click="changeCollection(item.id, item.collection)"
          >
            {{item.collection}}
          </div>
        </div>
      </div>
    </van-action-sheet>
    <layout></layout>
  </div>
</template>
<script>
import layout from '@/layout/index'
import {get_vod_info_get, get_recommend_list } from '@/api/play/toplay';
import {setfankui} from '@/api/Mue/index'
import noneImg from '@/assets/ic_default_varical.png';
import CryptoJS from 'crypto-js';
import '@/loading.css'
import {Toast} from "vant";

import slidex from '@/components/slide/slide-x'
import slidexitem from '@/components/slide/slide-x-item'
import { getCurrentDateAsYYYYMMDD, getCurrentHour, getCurrentMinuteInt } from "@/utils/init";
import {siteReport} from "@/api/log/log";

export default {
  name: '',
  components: {
    slidex,
    slidexitem,
    layout
  },
  data() {
    return {
      vodId: null,
      show: false,
      infos: [], //页面信息
      urlBtn: [], //播放路径的数据#ededed
      urlBtnActive: null, //播放路径的选中样式控制
      tvInfons: [], //集数的数据
      tvActive: null, //视频集数选中的样式控制
      screeninfo: [], // 相似影片数据
      screenPid: 1,//相似影片接口的参数
      videoSrc: null,//视频地址
      videoId: null,
      players: false,
      feedbackTags: [
          this.$t('contentProblem'),
          this.$t('playbackProblem'),
          this.$t('downloadProblem'),
          this.$t('abnormalExit'),
          this.$t('unableLogin'),
      ], //反馈信息数据
      radio: null, //
      message: null, //
      kongzhi: false, //
      setFan: {
        placeholder: null,// 当前视频的名字
        tvNum: null,//当前视频的集数
      },
      showPopover: false,
      seasonList: [],
      seasonTitle: null,
      typePid: 1,
      seasonShow: false,
      errorMessage:null,
    }
  },
  // 计算属性
  computed: {},
  watch: {
    '$route'() {
      // 在这里处理路由参数的变化，做出相应的操作
      location.reload()
    }
  },
  beforeRouteUpdate(to, from, next) {
    // 继续路由导航
    next();
  },
  created() {
  },
  // 关闭时触发的事件
  beforeDestroy() {
  },
  // mounted() 是组件创建后的一个阶段，即组件创建后调用info函数播放视频
  mounted() {
    this.load()
    window.addEventListener('changeCollection', this.listenerChangeCollection);
  },
  methods: {
    // 添加历史记录的方法
    addHistory() {
      const vodId = this.vodId
      const audioType = this.urlBtnActive
      const collection = this.tvActive
      const coverUrl = this.infos.vod_pic
      const vodName = this.infos.vod_name
      const audioLanguage = this.infos.audio_language_tag

      // 获取历史记录
      var historyStr = localStorage.getItem('user_history')
      var historyArr = []
      if (historyStr) {
        historyArr = JSON.parse(historyStr)
      }
      // 判断历史信息是否存在
      historyArr.forEach((item, index) => {
        if (item.vod_id === vodId) {
          historyArr.splice(index, 1);
        }
      });

      //判断数组长度
      // console.log(historyArr.length)
      if (historyArr.length >= 100) {
        historyArr = historyArr.slice(0, 99)
      }

      // 添加到首位
      historyArr.unshift({
        vod_id: vodId,
        audio_type: audioType,
        collection: collection,
        cover_url: coverUrl,
        vod_name: vodName,
        audio_language: audioLanguage
      })

      // 更新本地缓存
      localStorage.setItem('user_history', JSON.stringify(historyArr))
    },

    //左上角返回
    onClickLeft() {
      try {
        this.$router.go(-1);
      }catch (e) {
        this.$router.replace('/lnicio')
      }
      // if (this.$router.history.length > 1) {
      //   this.$router.go(-1); // 返回上一页
      // } else {
      //   // console.log('没有上级页面')
      //   this.$router.replace('/lnicio')
      // }
    },
    //获得反馈板块数据
    kongzhis() {
      this.message = null;
      this.radio = this.feedbackTags[0];
      this.kongzhi = false;
      this.errorMessage = ''
    },
    //点击弹出反馈页面
    openFeedback() {
      this.kongzhi = true;
      this.radio = this.feedbackTags[0]
    },
    //提交反馈内容
    onSubmit() {
      var _this = this
      if (! this.message) {
        this.errorMessage = this.$t('feedbackErrorTip')
      } else {
        this.errorMessage = ''
        const list = {
          type: 1,
          tags: this.radio,
          content: this.message,
          vod_id: this.infos.id,
          collection: this.setFan.tvNum
        }
        setfankui(list).then(() => {
          this.$toast({
            position: 'bottom',
            message: 'success',
            onClose: function () {
              _this.message = null;
              _this.radio = _this.feedbackTags[0];
              _this.kongzhi = false;
            }
          });
        })
      }
    },

    //获取页面初始化数据
    load() {
      const vod_id = this.$route.query.vod_id
      let audio_type = this.$route.query.audio_type
      // let collection = this.$route.query.collection

      audio_type = audio_type ? audio_type : 2
      // this.urlBtnActive = audio_type

      this.vodId = vod_id
      this.getVideoInfo(vod_id, audio_type)
    },

    // 修改音频
    changeAudio(index) {
      console.log(index)
      this.getVideoInfo(this.vodId, index)
    },

    getVideoInfo(vod_id, audioType = 2) {
      this.urlBtnActive = audioType;
      const cur_time = Date.now()
      const sign = 'ppcineweb123' + CryptoJS.MD5(navigator.userAgent).toString() + vod_id + cur_time;
      const parameter = {
        vod_id: vod_id,
        sign: CryptoJS.MD5(sign).toString().toUpperCase(),
        cur_time: cur_time,
        audio_type: audioType,
        date: getCurrentDateAsYYYYMMDD() + getCurrentHour() + Math.floor(getCurrentMinuteInt() / 10)
      }
      get_vod_info_get(parameter).then(res => {
        // console.log(res)
        // 获取本地缓存中存储播放的集数
        var collection = localStorage.getItem(vod_id)

        // 获取需要播放的id
        if (collection) {
          var collection_list = res.result.vod_collection;
          for (var i = 0; i < collection_list.length; i++) {
            if (collection_list[i]['collection'] == collection) {
              this.videoId = collection_list[i]['id']
            }
          }
        } else {
          this.videoId = res.result.vod_collection[0].id;
        }

        this.infos = res.result //播放页面的信息
        this.urlBtn = res.result.audio_type_option; //视频播放清晰度的数据
        this.urlBtnActive = res.result.audio_type; //视频的类型
        this.tvInfons = res.result.vod_collection; // 视频的集数
        this.tvActive = collection ? collection : res.result.vod_collection[0].collection; // 到播放页面时默认放第一集
        this.screenPid = res.result.type_pid; //传相似影片的的id
        this.setFan.placeholder = res.result.vod_name //当前视频的名字
        this.setFan.tvNum = 1
        this.typePid = res.result.type_pid
        if (this.typePid === 2) {
          this.seasonList = []
          var series_info = res.result.series_info;
          series_info.forEach((item) => {
            this.seasonList.push({
              text: item.series,
              disabled: item.default,
              vod_id: item.vod_id
            })
            if (item.default) {
              this.seasonTitle = item.series
            }
          });
        }

        //调用相似影片接口，设置数据
        get_recommend_list(
            {type_id: this.screenPid, is_random: 1, pn: 1, psize: 6, type: this.infos.vod_tag}
        ).then(res => {
          this.screeninfo = res.result
        })

        // 添加当前播放集数到缓存
        localStorage.setItem(vod_id, this.tvActive)
      })
    },

    //图片加载失败时的默认图
    setNewpic(event) {
      event.target.src = noneImg;
    },
    // 相似影片播放
    toPlays(vod_id) {
      this.freeIsok = false; // 当点击相似影片时 将vip的页面关掉
      this.$router.push({
        path: '/play',
        query: {
          'vod_id': vod_id
        }
      })
    },
    changeCollection(collection_id, collection) {
      this.videoId = collection_id
      this.tvActive = collection || 1;
      // console.log(this.videoId, this.tvActive)
      localStorage.setItem(this.vodId, this.tvActive)
    },
    toiosPlay() {
      if (this.videoId) {
        // console.log(this.videoId)

        siteReport({
          'app_id': 'h5_report',
          'sitename': '2',
        })

        var params = {
          "is_ad": 1,
          "collection_id": this.videoId,
          "vod_name": this.infos.vod_name
        }
        try {
          this.addHistory()
          // flutter
          window.flutter_inappwebview.callHandler('startPlaying', JSON.stringify(params));
        } catch (error) {
          try {
            // 原生ios
            window.startPlaying.postMessage(JSON.stringify(params));
          } catch (error) {
            try {
              // 原生android
              window.Netandroid.startPlaying(JSON.stringify(params));
            } catch (error) {
              console.log(error.message);
              siteReport({
                'app_id': 'h5_report',
                'sitename': '3',
              })
              alert(this.$t('playError'))
            }
          }
        }
      } else {
        console.log('播放链接暂时没有获取到')
        Toast({
          message: this.$t('playDataLoading'),
          position: 'bottom',
        });
      }
    },
    getDeviceType() {
      const userAgent = navigator.userAgent;
      if (userAgent.match(/Android/i)) {
        return 1;
      } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
        return 2;
      } else {
        return 3;
      }
    },
    changeSeason(action) {
      var vod_id = action.vod_id
      this.toPlays(vod_id)
    },
    download(){
      var params = {
        "audio_type": this.urlBtnActive,
        "vod_id": this.vodId,
        "collection": this.tvActive
      }
      try {
        window.flutter_inappwebview.callHandler('startDownload', JSON.stringify(params));
      } catch (error) {
        try {
          window.startDownload.postMessage(JSON.stringify(params));
        } catch (error) {
          console.log(error.message);
          Toast({
            message: this.$t('downloading'),
            position: 'bottom',
          });
        }
      }
    },
    listenerChangeCollection(event){
      Toast({
        message: event.detail.message,
        position: 'bottom',
      });
      console.log('Message from app:', event.detail.message);

      try {
        let jsonObject = JSON.parse(event.detail.message);
        let vodId = jsonObject.vodId
        let collection = jsonObject.collection

        this.tvActive = collection

        // 更新历史记录
        var historyStr = localStorage.getItem('user_history')
        var historyArr = []
        if (historyStr) {
          historyArr = JSON.parse(historyStr)
        }
        // 判断历史信息是否存在
        let historyInfo = {}
        historyArr.forEach((item, index) => {
          if (item.vod_id === vodId) {
            historyInfo = item
            historyArr.splice(index, 1);
          }
        });

        if (historyInfo) {
          historyInfo['collecion'] = collection
          historyArr.unshift(historyInfo)

          // 更新本地缓存
          localStorage.setItem('user_history', JSON.stringify(historyArr))
        }
      } catch (error) {
        console.error('解析 JSON 字符串时出错:', error);
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../../style/play/index.css';

.container {
  // background-color: #efefef;
  min-height: 100%;
}

#mse {
  width: 100vw;
  height: 30vh;
}

/deep/ .van-nav-bar {
  background: none;
  position: fixed;
  top: 0;
  left: 0;
}

/deep/ .van-nav-bar .van-icon {
  color: var(--title-color);
}

.content {
  font-family: PingFang SC;
  font-weight: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: var(--title-color);
  font-size: 14px;
}

.contentPublic {
  margin: 0 10px;
}
.episodes-list{
  padding: 0 10px;
  width: calc(100% - 20px);
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
}
/deep/ .episodes-list-content > .slide-x{
  padding: 0;
}
/deep/ .episodes-list-content > .slide-x > div{
  margin-right: 8.6px;
}
/deep/ .episodes-list-content > .slide-x > div:last-child{
  margin-right: 0;
}

.episodes-list-content-item{
  width: 52px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--minor-color);
  border-radius: 3px;
  font-size: 18px;
}

.episodes-list-content-item-active{
  width: 50px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--minor-color);
  border-radius: 3px;
  font-size: 18px;
  border: 1px solid #ec2741;
  color: #ec2741;
}

.season-sheet-content {
  font-family: PingFang SC;
  font-weight: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: var(--title-color);
  font-size: 14px;
  //padding: 0 10px;
}
/deep/ .van-action-sheet__header {
  background-color: var(--main-color);
  padding: 0 10px;
  height: 49px;
  color: var(--title-color);
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid var(--minor-text-color);
}
/deep/ .van-action-sheet__close{
  padding: 0 10px 0 0;
  color: var(--title-color);
  font-size: 16px;
}

.season-sheet-content-bottom{
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.season-sheet-content-bottom-item{
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--minor-color);
  border-radius: 3px;
  font-size: 18px;
  margin-right: 8.6px;
  margin-bottom: 8.6px;
  border: 1px solid var(--minor-color);
}
.season-sheet-content-bottom-item:nth-child(6n){
  margin-right: 0;
}
.season-sheet-content-bottom-item-active{
  border: 1px solid #ec2741;
  color: #ec2741;
}

/deep/ .van-action-sheet__header {
  text-align: inherit;
}

/deep/ .van-action-sheet__content {
  background: var(--main-color)
}

/deep/ .van-action-sheet{
  height: 70vh;
}

.rec_vod_name {
  width: 3.1rem;
  font-size: 14px;
  line-height: 24px;
  color: var(--minor-text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.video-title {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  color: var(--title-color);
  font-size: 20px;
  align-items: center;
}

.video-title-name {
  white-space: nowrap;
  width: 6.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.video-title-info {
  font-size: 14px;
  color: var(--minor-text-color);
  font-weight: inherit;
}

.season-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px 10px;
}

.season-collection {
  font-size: 14px;
  color: var(--minor-text-color);
}

.season-info-button {
  padding: 5px 10px;
  background-color: var(--main-color);
  color: var(--title-color);
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid var(--title-color);
}

// 影片名称样式
.cellss {
  padding: 10px 10px;
  color: var(--title-color);
  font-size: 20px;
  font-weight: bold;
}

.cellss > div:last-child {
  font-size: 14px;
  opacity: 0.6;
}

.intro {
  font-size: 12px;
  color: var(--minor-text-color);
  padding: 10px 0 10px 0;
}

.synopsis {
  font-size: 12px;
  color: var(--minor-text-color);
  padding: 0 10px;
}

// 评分
.score {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: 0px;
  color: var(--minor-text-color);
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.vod-basics-info {
  display: flex;
  align-items: center;
}
.vod-tag {
  padding-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.vod-basics-info > div:nth-of-type(2) {
  padding: 0 10px;
  opacity: 0.3;
}

.operation{
  display: flex;
  color: white;
  justify-content: flex-end;
  padding: 0 10px;
  margin-top: 5px;
  font-size: 10px;
}
.operation-item{
  padding-left: 5px;
}

.operation-item-image{
  width: 100%;
  display: flex;
  justify-content: center
}

.operation-item-image-content {
  width: 25px;
  height: 25px;
}

.xff {
  display: flex;
  justify-content: space-between;
  padding: 15px 5px;
}

.vipBtn {
  width: 164px;
  height: 32px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #663e0c;
  background-image: linear-gradient(180deg,
      #feecd0 0%,
      #fecf89 100%);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.xff-left {
  display: flex;
}

.xff-left img {
  width: 25px;
  height: 25px;
  padding-left: 20px;
}

//  音频样式
.playUrl {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--title-color);
  opacity: 0.8;
  padding: 5px 10px 10px 10px;
}

.Urls {
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px 15px;
  background-color: var(--minor-color);
  border-radius: 4px;
  display: inline-block;
}

//  选中的音频样式
.urlsActive {
  //margin-top: 10px;
  margin-right: 10px;
  padding: 10px 15px;
  background-color: red;
  border-radius: 4px;
  display: inline-block;

}

///deep/.van-swipe__track {
//  padding-left: 10px;
//}

.playNum {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: var(--title-color);
  opacity: 0.8;
}

.numNav {
  margin: 10px 5px;
  display: flex;
  justify-content: space-between;
}

.numBox {
  margin: 0 10px;
}

//相似影片的样式
.swipeScreen {
  width: 10px;
  border-radius: 2px;
}

//反馈信息板块样式
.wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block {
  width: 80vw;
  padding: 10px;
  font-family: PingFang SC;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 21px;
  letter-spacing: 0px;
  color: var(--title-color);
  background-color: var(--minor-color);
  border-radius: 5px;
}

.block > div:first-child {
  font-size: 20px;
  text-align: center;
}

.overlay-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.overlay-close {
  margin-top: 10px;
  font-size: 30px;
}

.overlay-image {
  border-radius: 5px;
}

.overlay-image > .van-image {
  overflow: hidden;
  border-radius: 10px;
  max-height: 70vh;
  max-width: 80vw;
  min-height: 300px
}

.video-content {
  width: 100%;
  height: 30vh;
  background: var(--minor-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

/deep/ .van-radio__label {
  color: var(--title-color);
}

/deep/ .van-button--round {
  margin-top: 20px;
}

/deep/ .van-radio {
  margin: 10px 0;
}

/deep/ .van-cell {
  background-color: #3b4148;
  font-size: 12px;
  border-radius: 5px;
}

/deep/ .van-cell::after {
  border: none;
}

/deep/ .van-field__control {
  color: var(--title-color);
}

// 显示时间
/deep/ .video-js .vjs-time-control {
  display: block;
}

/deep/ .video-js .vjs-remaining-time {
  display: none;
}

// 播放按钮样式
/deep/ .vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}

/deep/ .video-js .vjs-big-play-button {
  border: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-top: -0.75rem;
  margin-left: -0.75rem;
}

/deep/ .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  position: initial !important;
}

/deep/ .van-radio__icon--checked .van-icon {
  color: var(--title-color);
  background-color: var(--minor-color);
  border-color: var(--title-color);
}

/deep/ .van-skeleton__row {
  //height: 24px;
  background-color: #999999;
}

/deep/ .van-popover__wrapper {
  display: block;
}

///deep/ .van-swipe-item{
//  display: flex;
//  justify-content: center;
//}
</style>
