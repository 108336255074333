import request from '@/utils/requests'
import qs from 'qs'

export const get_vod_info = data => {
    return  request ({
        url: '/vod/info_web',
        method: 'post',
        data: qs.stringify(data)
    })
}

export const get_vod_info_get = data => {
    return  request ({
        url: '/vod/info_web_get',
        method: 'get',
        params: data,
    })
}

// 添加历史记录
export const add_history = data => {
    return  request ({
        url: '/user_history/add',
        method: 'post',
        data: qs.stringify(data)
    })
}

// 相似影片接口
export const get_recommend_list = data => {
    return request({
        url: '/search/screen',
        method: 'post',
        data: qs.stringify(data)
    })
}

//获取播放地址防盗链cookie
export const urlCookies = data => {
    return request({
        url: '/vod/get_vod_url_cookie',
        method: 'post',
        data: qs.stringify(data),
    })
}
// import { toPlay } from '@/api/play/toplay'
